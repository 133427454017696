<template>
	<article :id="event.id">
		<div class="single-post-heading">
			<h6 class="font-w-600 text-uppercase"><a v-if="event.location" v-text="event.location.title"></a></h6>
			<router-link :to="{ name: 'Veranstaltung', params: { id: event.id } }">
				<span v-if="event.canceled" class="badge bg-danger">Abgesagt</span>
				<h2 :class="['m-0 h2 fw-light', {'text-decoration-line-through': event.canceled}]" v-if="event.post" v-text="event.post.title"></h2>
			</router-link>
			<div class="border-top pt-3 mt-3">
				<div class="pb-2">
					<p class="m-0" v-if="event.post" v-html="event.post.text"></p>
				</div>
				<div class="nav small">
					<span class="pe-3 font-w-800" v-if="event" v-text="getDate(event)"></span>
					<span class="pe-3" v-if="event.location" v-html="getAddress(event)"></span>
				</div>
			</div>
		</div>
		<div class="nav tag-style pt-3">
			<a>Reutlingen</a>
			<a v-if="event.type == 'public'">Öffentlich</a>
			<a v-if="event.hasband == true">Band</a>
			<a v-if="event.location && event.location.hascatering == true">Catering</a>
			<a v-if="event.corona_restriction == true">Corona-Beschränkungen</a>
		</div>
	</article>
</template>
<script>
import moment from 'moment'
export default {
	name: 'EventItem',
	props: ['event'],
	methods: {
		getAddress(event)
		{
			return (event.location) ? `<b>${event.location.title}</b>, ${event.location.street}, ${event.location.town}` : null;
		},
		getDate(event)
		{
			return (event.date_start) ? `${moment(event.date_start).format('LL')}, ab ${moment(event.date_start).format('HH:mm')} Uhr` : null;
		}
	},
};
</script>