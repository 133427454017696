<template>
	<div class="events">
		<SectionHeader :image="null" title="Veranstaltungen" page="Alle Veranstaltungen" />
		<section class="section">
			<div class="py-5 py-lg-7 single-post-type-01">
				<div class="container">
					<div class="row g-3">
						<div class="col-lg-8">
							<EventList v-for="event in events" :key="event.id">
								<EventItem :event="event"></EventItem>
							</EventList>
						</div>
						<div class="col-lg-4 ps-xxl-8">
							<div class="card mb-4">
								<div class="card-header">
									<h5 class="text-center mb-3">Ort</h5>
									<span class="d-block w-40px h-4px bg-primary mx-auto rounded-3"></span>
								</div>
								<div class="card-body">
									<ul class="list-unstyled list-type-01 primary-link font-w-500">
										<li>
											<i class="bi bi-chevron-right text-primary"></i>
											<a class="d-flex" @click="filterLocation('reutlingen')">Reutlingen <span class="ms-auto font-w-400 text-body">3</span> </a>
										</li>
										<li>
											<i class="bi bi-chevron-right text-primary"></i>
											<a class="d-flex" @click="filterLocation('germany')">Deutschland <span class="ms-auto font-w-400 text-body">3</span> </a>
										</li>
										<li>
											<i class="bi bi-chevron-right text-primary"></i>
											<a class="d-flex" @click="filterLocation('romania')">Rumänien <span class="ms-auto font-w-400 text-body">0</span> </a>
										</li>
									</ul>
								</div>
							</div>
							<div class="card mb-4">
								<div class="card-header">
									<h5 class="text-center mb-3">Jahr</h5>
									<span class="d-block w-40px h-4px bg-primary mx-auto rounded-3"></span>
								</div>
								<div class="card-body">
									<ul class="list-unstyled list-type-01 primary-link font-w-500">
										<li>
											<i class="bi bi-chevron-right text-primary"></i>
											<a class="d-flex">2022 <span class="ms-auto font-w-400 text-body">3</span> </a>
										</li>
										<li>
											<i class="bi bi-chevron-right text-primary"></i>
											<a class="d-flex">2023 <span class="ms-auto font-w-400 text-body">0</span> </a>
										</li>
									</ul>
								</div>
							</div>
							<div class="card mb-4">
								<div class="card-header">
									<h5 class="text-center mb-3">Status</h5>
									<span class="d-block w-40px h-4px bg-primary mx-auto rounded-3"></span>
								</div>
								<div class="card-body">
									<ul class="list-unstyled list-type-01 primary-link font-w-500">
										<li>
											<i class="bi bi-chevron-right text-primary"></i>
											<a class="d-flex" @click="filterType('public')">Öffentlich <span class="ms-auto font-w-400 text-body">0</span> </a>
										</li>
										<li>
											<i class="bi bi-chevron-right text-primary"></i>
											<a class="d-flex" @click="filterType('internal')">Intern <span class="ms-auto font-w-400 text-body">0</span> </a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import _ from 'lodash'
import EventList from '@/views/EventList.vue'
import EventItem from '@/views/EventItem.vue'
import SectionHeader from '@/views/SectionHeader.vue'
export default {
	name: "Veranstaltungen",
	components: {
		EventList,
		EventItem,
		SectionHeader,
	},
	methods: {
	},
	computed: {
		events() {
			return _.orderBy(this.$store.state.events, 'date_start', 'asc');
		},
		loading() {
			return this.$store.getters.loadingStatus;
		}
	},
	created() {
		this.$store.dispatch('loadEvents');
	},
	mounted () {
		window.scrollTo(0, 0)
	}
};
</script>
<style scoped>
section.section { padding: 10px; }
</style>